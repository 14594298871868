<template>
  <div class="sketch-canvas">
    <div class="sketch-board" :class="isErasing ? 'square-cursor' : 'cross-cursor'">
      <canvas ref="canvas" @touchstart.prevent="startDrawingTouch" @touchmove.prevent="drawTouch"
        @touchend.prevent="stopDrawing" @touchcancel.prevent="stopDrawing" @mousedown.prevent="startDrawing"
        @mousemove.prevent="draw" @mouseup.prevent="stopDrawing" width="580px" height="580px"></canvas>
    </div>
    <div class="sketch-buttons">
      <el-row>
        <el-tooltip effect="light" content="Reset" placement="right" :hide-after="1500" :disabled="isMobile">
          <el-button icon="el-icon-refresh" @click="clickReset"></el-button>
        </el-tooltip>
      </el-row>
      <el-row>
        <el-button v-if="isMobile" icon="el-icon-arrow-left" @click="clickUndo" />
        <el-tooltip v-else effect="light" content="Undo" placement="right" :hide-after="1500" :disabled="isMobile">
          <el-button icon="el-icon-arrow-left" @click="clickUndo"></el-button>
        </el-tooltip>
      </el-row>
      <el-row>
        <el-tooltip effect="light" content="Clear" placement="right" :hide-after="1500" :disabled="isMobile">
          <el-button v-if="isErasing" icon="el-icon-edit" @click="clickEraser"></el-button>
          <el-button v-else @click="clickEraser">
            <svg-icon icon-class="eraser-btn" />
          </el-button>
        </el-tooltip>
      </el-row>
      <el-row>
        <el-tooltip effect="light" content="Use brush" placement="right" :hide-after="1500" :disabled="isMobile">
          <el-popover placement="right" width="200" trigger="click" popper-class="wrap-popover">
            <el-button slot="reference" icon="el-icon-brush"></el-button>
            <el-slider v-model="brushVal" :step="1" :min="1" :max="10" :show-tooltip="!isMobile" show-stops>
            </el-slider>
          </el-popover>
        </el-tooltip>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isMobile: false,
      isDrawing: false,
      isErasing: false,
      context: null,
      history: [],
      lastX: 0,
      lastY: 0,
      brushVal: 1,
    }
  },
  mounted() {
    this.checkDevice();
    this.context = this.$refs.canvas.getContext('2d');
    this.context.fillStyle = 'white';
    this.context.fillRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
  },
  methods: {
    checkDevice() {
      this.isMobile = 'ontouchstart' in document.documentElement;
      console.log("check device result: isMobile ==", this.isMobile);
    },
    startDrawingTouch(e) {
      const { clientX, clientY } = e.touches[0];
      this.isDrawing = true;
      let rect = this.$refs.canvas.getBoundingClientRect();
      this.lastX = clientX - rect.left;
      this.lastY = clientY - rect.top;
    },
    startDrawing(e) {
      this.isDrawing = true;
      let rect = this.$refs.canvas.getBoundingClientRect();
      this.lastX = e.clientX - rect.left;
      this.lastY = e.clientY - rect.top;
    },
    drawTouch(e) {
      if (!this.isDrawing) return;
      if (this.isErasing) {
        const { clientX, clientY } = e.touches[0];
        let rect = this.$refs.canvas.getBoundingClientRect();
        const offsetX = clientX - rect.left;
        const offsetY = clientY - rect.top;
        this.context.lineWidth = 16;
        this.context.clearRect(offsetX - 8, offsetY - 8,
          this.context.lineWidth, this.context.lineWidth);
      } else {
        const { clientX, clientY } = e.touches[0];
        this.context.strokeStyle = '#000';
        this.context.lineWidth = this.brushVal;
        this.context.beginPath();
        this.context.moveTo(this.lastX, this.lastY);
        let rect = this.$refs.canvas.getBoundingClientRect();
        this.context.lineTo(clientX - rect.left, clientY - rect.top);
        this.context.stroke();

        this.lastX = clientX - rect.left;
        this.lastY = clientY - rect.top;
      }
    },
    draw(e) {
      if (!this.isDrawing) return;
      if (this.isErasing) {
        this.context.lineWidth = 12;
        this.context.clearRect(e.offsetX,
          e.offsetY,
          this.context.lineWidth, this.context.lineWidth);
      } else {
        this.context.strokeStyle = '#000';
        this.context.lineWidth = this.brushVal;
        this.context.beginPath();
        this.context.moveTo(this.lastX, this.lastY);
        let rect = this.$refs.canvas.getBoundingClientRect();
        this.context.lineTo(e.clientX - rect.left, e.clientY - rect.top);
        this.context.stroke();

        this.lastX = e.clientX - rect.left;
        this.lastY = e.clientY - rect.top;
      }
    },
    stopDrawing() {
      this.isDrawing = false;
      this.context.beginPath();
      this.history.push(this.$refs.canvas.toDataURL());
    },
    clickReset() {
      this.context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
      this.history = [];
    },
    clickUndo() {
      if (this.history.length > 1) {
        this.history.pop();
        const lastImage = this.history[this.history.length - 1];
        const img = new Image();
        img.src = lastImage;
        img.onload = () => {
          this.context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
          this.context.drawImage(img, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        };
      } else {
        this.clickReset();
      }
    },
    clickEraser() {
      this.isErasing = !this.isErasing;
    },
    saveImage() {
      return this.$refs.canvas.toDataURL('image/png');
    }
  },
}
</script>

<style lang="scss" scoped>
.sketch-canvas {
  .sketch-board {
    float: left;
    margin-top: 10px;
    background-color: white;
    width: 580px;
    height: 580px;
  }

  .sketch-buttons {
    float: left;
    margin: 10px;

    .el-row {
      margin-bottom: 5px;

      .el-button {
        padding: 5px;
        font-size: 18px;
      }


    }
  }
}

/deep/ .el-slider__stop {
  background-color: #e4e7ed;
}

.square-cursor {
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAHYcAAB2HAY/l8WUAAAAwSURBVChTlcjBCQAgDMDA7r90dYAzYOA+mdt+4iychbNwFs7CWTgLZ+EsnIXzYfYA71GPcTrjKCQAAAAASUVORK5CYII='), auto;
}

.cross-cursor {
  cursor: crosshair;
}
</style>